<template>
  <div class="m-feedback">
    <div class="container">
      <div class="title-template">
        <p>家长<span :style="{color: colorStyle}">反馈</span></p>
      </div>
      <div class="feedback-left">
        <div class="feedback-box" v-for="(val, idx) in list.slice(0, list.length/2)" :key="idx">
          <img :src="val.avatar" alt="">
          <span class="grade">{{val.grade}}</span>
          <span class="name" :style="{color: colorStyle}">{{val.name}}</span>
          <div class="content"><p v-for="(item, index) in val.content" :key="index">{{item}}</p></div>
          <i class="line" :style="{background: colorStyle}"></i>
        </div>
      </div>
      <div class="feedback-right">
        <div class="feedback-box" v-for="(val, idx) in list.slice(list.length/2)" :key="idx">
          <img :src="val.avatar" alt="">
          <span class="grade">{{val.grade}}</span>
          <span class="name" :style="{color: colorStyle}">{{val.name}}</span>
          <div class="content"><p v-for="(item, index) in val.content" :key="index">{{item}}</p></div>
          <i class="line" :style="{background: colorStyle}"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "mFeedBack",
  props: {
    list: { // 内容
      type: Array,
      required: true
    },
    colorStyle: { // 文字颜色
      type: String,
      required: true
    },
  },
  data() {
    return {
      
    }
  },
  created () { 
    },
  mounted () {
    
  },
  methods: {

  }
};
</script>
<style lang="scss" scoped>
.m-feedback{
  width: 100%;
  .title-template{
    text-align: center;
    padding-bottom: .613rem;
    >p{
      font-size:.587rem;
      font-family:MicrosoftYaHei-Bold;
      font-weight:bold;
      color:rgba(68,65,68,1);
      line-height:.853rem;
      letter-spacing: .013rem;
    }
  }
  .container{
    width: 9.04rem;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .title-template{
      width: 100%;
    }
    .feedback-left{
      width: 4.4rem;
    }
    .feedback-right{
      width: 4.4rem;
    }
    .feedback-box{
      text-align: left;
      width: 100%;
      background:rgba(255,255,255,1);
      box-shadow:0rem .213rem .213rem 0rem rgba(200,198,191,0.3);
      border-radius:.213rem;
      box-sizing: border-box;
      padding: .293rem .373rem .4rem;
      margin-bottom: .4rem;
      position: relative;
      i{
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width:4.4rem;
        height:.053rem;
        border-radius: .213rem .213rem 0 0;
      }
      img{
        width:.72rem;
        height:.72rem;
        border-radius:.36rem;
      }
      span{
        display: block;
        width:100%;
      }
      .grade{
        font-size:.267rem;
        font-family:PingFang-SC-Regular;
        font-weight:400;
        color:rgba(136,136,136,1);
        margin: .2rem 0 .133rem;
      }
      .name{
        font-size:.373rem;
        font-family:PingFang-SC-Bold;
        font-weight:bold;
        line-height:.48rem;
        margin-bottom: .2rem;
      }
      .content{
        font-size:.32rem;
        font-family:PingFang-SC-Regular;
        font-weight:400;
        color:rgba(68,65,68,1);
        line-height:.5rem;
        text-indent: 2em;
      }
    }
  }
}
</style>