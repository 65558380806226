<template>
  <div class="receive" @touchmove.prevent  v-if="receiveShow">
    <h3>关注公众号领取课程
      <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/mask-close.png" @click.stop="closemask">
    </h3>
    <ul>
      <li>1、长按保存下方二维码</li>
      <li>2、打开微信扫一扫，扫描系统相册中二维码</li>
      <li>3、关注“河小象”公众号领取课程</li>
    </ul>
    <div class="qrcode">
      <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/wxapp_code.jpg">
      <p>长按保存到手机</p>
    </div>
  </div>
</template>
<script>
export default {
     props:{
        receiveShow: { //显示
        type: Boolean,
        required: true
        }
    },
  data(){
    return{

    }
  },
  methods:{
    closemask(){
      this.$emit('closeMask')
    }
  }
}
</script>
<style lang="scss" scoped>
.receive{
  position: fixed;
  left: 0;
  top: 0;
  right:0;
  bottom: 0;
  background:rgba(0,0,0,.8);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3{
    font-size: .64rem;
    color: #FA6400;
    margin-bottom: .427rem;
    position: relative;
    img{
      position: absolute;
      top: -1rem;
      right: -1rem;
    }
  }
  ul{
    padding:0 .8rem;
    li{
      font-size: .427rem;
      color: #fff;
      text-align: left;
      line-height: .587rem;
    }
  }
  .qrcode{
    margin-top: .8rem;
    border: .08rem solid #FF9E20;
    border-radius: .32rem;
    width: 3.2rem;
    height: 3.2rem; 
    img{
      width: 100%;
      border-radius: .28rem;
    }
    p{
      font-size: .373rem;
      color: #fff;
      margin-top: .267rem;
    }
  }
}
</style>
