<template>
  <div class="m-tool">
    <img
      src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/m-service.png"
      alt="客服"
      @click="contact"
    >
    <img
      v-if="downloadShow"
      src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/m-download.png"
      alt="下载"
      @click="downloadApp"
      class="downloadBtn"
    >
    <img
      v-if="topShow"
      src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/top.png"
      alt="回到顶部"
      @click="goTop"
      class="goTop"
    >
    <div class="mask" v-show="show">
      <div class="mask-content">
        <h2>咨询电话</h2>
        <a :href="'tel:' + phoneNumber">{{phoneNumber}}</a>
        <img
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/mask-close.png"
          class="mask-close"
          @click="show=false"
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "mTool",
  data() {
    return {      
      show: false,
      topShow: false
    };
  },
  computed: {
    phoneNumber: function() {
      let host = window.location.host;
      return host.match('hxxbaby.com') ? '15314683489' : '400-163-3686';
    }    
  },
  props: {
    downloadShow: {
      type: Boolean
    }
  },
  mounted: function() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  watch:{
      $route (to, from) {
      this.topShow = false
    }
  },
  methods: {
    // // 调用拨号功能
    // callPhone () {
    //     window.location.href = 'tel://' + this.phoneNumber;
    // }
    downloadApp() {
      var u = navigator.userAgent,
        isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1,
        isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
        urls = {
          android:
            "https://android.myapp.com/myapp/detail.htm?apkName=com.mobcoreapp&ADTAG=mobile",
          ios: "https://itunes.apple.com/cn/app/%E6%B2%B3%E5%B0%8F%E8%B1%A1/id1436282840?mt=8"
        };
      if (isAndroid) {
        window.location.href = urls.android;
      } else if (isiOS) {
        window.location.href = urls.ios;
      }
    },
    contact() {
      this.show = true;
    },
    goTop() {
      (function smoothscroll() {
        var currentScroll =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
          window.requestAnimationFrame(smoothscroll);
          window.scrollTo(0, currentScroll - currentScroll / 5);
        }
      })();
      this.topShow=false
    },
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if(scrollTop>0){
        this.topShow=true
      }else{
        this.topShow=false
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.m-tool {
  position: fixed;
  right: 0;
  bottom: 0.6rem;
  width: 1.587rem;
  img {
    display: block;
    width: 1.3rem;
    height: 1.3rem;
    margin-bottom: 0.2rem;
  }
  .downloadBtn {
    margin-bottom: 1rem;
  }
  .mask {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 8;
    .mask-content {
      width: 7.547rem;
      height: 5.12rem;
      background-color: #fff;
      border-radius: 0.213rem;
      position: absolute;
      top: 6rem;
      h2 {
        font-size: 0.48rem;
        color: #2d2e3f;
        margin: 0.64rem 0 0.933rem;
      }
      a {
        font-size: 0.64rem;
        color: #32c5ff;
      }
      .mask-close {
        position: absolute;
        right: 0.213rem;
        top: 0.213rem;
        width: 0.64rem;
        height: 0.64rem;
      }
    }
  }
}
</style>
